<template>
  <div class="Commerce">
    <img src="../../assets/product/Commerce/1.png" class="headerimg" />
    <div class="advantage">
      <div class="title contertop">邦伲德服务优势</div>

      <div class="advantagelist">
        <div class="advantageimg">
          <img src="../../assets/product/Commerce/2.png" alt="" />
        </div>
        <div class="advantagecard">
          <div class="advantageline">
            <div class="cardimg">
              <img src="../../assets/product/Commerce/3.png" alt="" />
            </div>
            <div class="advantageinfo">
              <div class="advantagetitle">资深会计团队</div>
              <div class="cardtext">
                从代办注册公司、报到、税控审批，专业服务，让您省心省力。
              </div>
            </div>
          </div>

          <div class="advantageline topandbottom">
            <div class="cardimg">
              <img src="../../assets/product/Commerce/4.png" alt="" />
            </div>
            <div class="advantageinfo">
              <div class="advantagetitle">公司注册流程短</div>
              <div class="cardtext">
                标准化工商注册公司代理流程，一站式服务，全程专业化办理流程短。
              </div>
            </div>
          </div>

          <div class="advantageline">
            <div class="cardimg">
              <img src="../../assets/product/Commerce/5.png" alt="" />
            </div>
            <div class="advantageinfo">
              <div class="advantagetitle">透明的公司注册费用</div>
              <div class="cardtext">
                按需收取公司注册费用，收费一次性无隐形收费，帮您省钱。
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="information contertop">
        <div class="title">注册公司需要准备的资料</div>
        <div class="informationimg">
          <img src="../../assets/product/Commerce/6.png" alt="" />
        </div>
      </div>

      <div class="flow contertop">
        <div class="title">公司注册流程</div>
        <div class="advantagelist">
          <div class="advantagecard">
            <div class="cardheard">核名查询</div>
            <div class="cardinfo">注册公司核名查询，核验准备的名称是否可用</div>
          </div>

          <div class="advantagecard">
            <div class="cardheard">提交材料</div>
            <div class="cardinfo">
              提交股东身份原件复印件、 固定电话、邮箱等相关材料
            </div>
          </div>

          <div class="advantagecard">
            <div class="cardheard">领取营业执照</div>
            <div class="cardinfo">
              帮您跑公司注册流程，工商部门审核通过后领证
            </div>
          </div>

          <div class="advantagecard">
            <div class="cardheard">刻章等事项</div>
            <div class="cardinfo">财税顾问协助刻章以及其他注册公司后续问题</div>
          </div>

          <div class="advantagecard">
            <div class="cardheard">售后财税咨询</div>
            <div class="cardinfo">专属财税顾问免费提供日常财务账目管理建议</div>
          </div>
        </div>
      </div>

      <div class="getinfo contertop">
        <div class="title">您能获得的资料</div>
        <div class="advantagelist">
          <div class="advantagecard">
            <div class="cardimg">
              <img
                src="../../assets/product/Commerce/7.png"
                alt=""
                class="img1"
              />
            </div>
            <div class="cardtext">营业执照正本</div>
          </div>

          <div class="advantagecard">
            <div class="cardimg">
              <img
                src="../../assets/product/Commerce/8.png"
                alt=""
                class="img2"
              />
            </div>
            <div class="cardtext">营业执照副本</div>
          </div>

          <div class="advantagecard">
            <div class="cardimg">
              <img
                src="../../assets/product/Commerce/9.png"
                alt=""
                class="img3"
              />
            </div>
            <div class="cardtext">企业统一社会信用代码证明</div>
          </div>

          <div class="advantagecard">
            <div class="cardimg">
              <img
                src="../../assets/product/Commerce/10.png"
                alt=""
                class="img4"
              />
            </div>
            <div class="cardtext">公司章程</div>
          </div>

          <div class="advantagecard">
            <div class="cardimg">
              <img
                src="../../assets/product/Commerce/11.png"
                alt=""
                class="img5"
              />
            </div>
            <div class="cardtext">公司印章及印鉴存留卡</div>
          </div>
        </div>
      </div>

      <div class="offer contertop conterbottom">
        <div class="title">我们还提供</div>
        <div class="advantagelist">
          <div class="advantagecard">
            <div class="cardimg">
              <img src="../../assets/product/Commerce/12.png" alt="">
            </div>
            <div class="cardtext">
              代理记账
            </div>
          </div>
          <div class="advantagecard">
            <div class="cardimg">
              <img src="../../assets/product/Commerce/13.png" alt="">
            </div>
            <div class="cardtext">
              公司审计
            </div>
          </div>
          <div class="advantagecard">
            <div class="cardimg">
              <img src="../../assets/product/Commerce/14.png" alt="">
            </div>
            <div class="cardtext">
              工商换照
            </div>
          </div>
          <div class="advantagecard">
            <div class="cardimg">
              <img src="../../assets/product/Commerce/15.png" alt="">
            </div>
            <div class="cardtext">
              知识产权
            </div>
          </div>
          <div class="advantagecard">
            <div class="cardimg">
              <img src="../../assets/product/Commerce/16.png" alt="">
            </div>
            <div class="cardtext">
              企业资质
            </div>
          </div>
          <div class="advantagecard">
            <div class="cardimg">
              <img src="../../assets/product/Commerce/12.png" alt="">
            </div>
            <div class="cardtext">
              政府补助
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.advantageimg {
  img {
    width: 675px;
  }
}

.flow .advantagelist,
.getinfo .advantagelist,
.offer .advantagelist {
  justify-content: space-between;
  width: 80%;
  margin: auto;
  margin-top: 60px;
}

.offer .advantagecard {
  margin-left: 0px !important;
  width: 200px;
  height: 200px;
  background: #ffffff;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  text-align: center;
  .cardimg {
    margin-top: 38px;
    margin-bottom: 37px;
    img {
      width: 70px;
    }
  }
  .cardtext {
    font-size: 24px;
    font-weight: 400;
    color: #333333;
    line-height: 36px;
  }
}

.getinfo .advantagecard {
  margin-left: 0px !important;
  .cardimg {
    width: 259px;
    height: 289px;
    background: #f5f5f9;
    text-align: center;
    .img1 {
      width: 199px;
      margin-top: 14px;
    }
    .img2 {
      width: 193px;
      margin-top: 14px;
    }
    .img3 {
      width: 238px;
      margin-top: 64x;
    }
    .img4 {
      width: 189px;
      margin-top: 11px;
    }
    .img5 {
      width: 245px;
      margin-top: 75px;
    }
  }
  .cardtext {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #3e3e3e;
    line-height: 21px;
    margin-top: 20px;
  }
}

.flow .advantagecard {
  width: 259px;
  height: 182px;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
  border-radius: 14px 14px 0px 0px;
  margin-left: 0px !important;
  .cardheard {
    width: 259px;
    height: 82px;
    background: linear-gradient(90deg, #feb26a 0%, #fc5d28 100%);
    border-radius: 14px 14px 0px 0px;
    text-align: center;
    line-height: 82px;
    color: #fff;
    font-size: 30px;
  }

  .cardinfo {
    padding: 30px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #616b80;
    line-height: 20px;
  }
}
</style>